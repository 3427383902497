
body {
    background: linear-gradient(102deg, rgba(249, 249, 249, 0.1) 0%, rgba(249, 249, 249, 0.1) 14%, rgba(161, 161, 161, 0.1) 14%, rgba(161, 161, 161, 0.1) 100%),
                linear-gradient(310deg, rgba(26, 26, 26, 0.03) 0%, rgba(26, 26, 26, 0.03) 71%, rgba(189, 189, 189, 0.03) 71%, rgba(189, 189, 189, 0.03) 100%),
                linear-gradient(133deg, rgba(132, 132, 132, 0.1) 0%, rgba(132, 132, 132, 0.1) 8%, rgba(95, 95, 95, 0.1) 8%, rgba(95, 95, 95, 0.1) 100%),
                linear-gradient(142deg, rgba(110, 110, 110, 0.03) 0%, rgba(110, 110, 110, 0.03) 46%, rgba(8, 8, 8, 0.03) 46%, rgba(8, 8, 8, 0.03) 100%),
                linear-gradient(346deg, rgba(231, 231, 231, 0.05) 0%, rgba(231, 231, 231, 0.05) 6%, rgba(71, 71, 71, 0.05) 6%, rgba(71, 71, 71, 0.05) 100%),
                linear-gradient(118deg, rgba(251, 251, 251, 0.07) 0%, rgba(251, 251, 251, 0.07) 72%, rgba(24, 24, 24, 0.07) 72%, rgba(24, 24, 24, 0.07) 100%),
                linear-gradient(338deg, rgba(75, 75, 75, 0.08) 0%, rgba(75, 75, 75, 0.08) 37%, rgba(213, 213, 213, 0.08) 37%, rgba(213, 213, 213, 0.08) 100%),
                linear-gradient(90deg, rgb(145, 231, 255), rgb(255, 230, 162));
    height: 100vh;
}

@media only screen and (min-width: 600px) {
    h1 {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 700px) {
    h1 {
        font-size: 4.2rem;
    }
    .popup-inner {
        left: 50%;
        transform: translate(-50%);
        width: 50vh;
        overflow: hidden !important;
    }
}



















